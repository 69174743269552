<template>
  <v-container
    id="regular-tables-view"
    fluid
    tag="section"
  >
    <material-card
      icon="mdi-clipboard-text"
      icon-small
      title="Типи акцій"
      color="accent"
      class="mb-16"
    >
      <!--Таблица-->
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">
              ID
            </th>
            <th class="primary--text">
              Вкл
            </th>
            <th class="primary--text">
              Назва
            </th>
            <th width="50"></th>
          </tr>
        </thead>

        <tbody v-if="actionsTypes.length">
          <tr v-for="(action, index) in actionsTypes" :key="`action-${action.id}`">
            <td>{{ action.id }}</td>
            <td>
              <v-chip small outlined :color="action.enabled ? 'green' : ''">
                {{ action.enabled ? 'Так' : 'Нi' }}
              </v-chip>
            </td>
            <td>{{ action.name }}</td>
            <td>
              <span class="d-flex">
                <v-icon
                  v-if="isManager || isAdmin"
                  class="mr-4"
                  color="green"
                  @click="$router.push(`/action-type/${action.id }/`)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  v-if="isAdmin"
                  color="error"
                  @click="showDialogDelete(action.id)"
                >
                  mdi-delete
                </v-icon>
              </span>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </material-card>

    <!--Кнопка добавить тип акции-->
    <v-btn
      v-if="isManager || isAdmin"
      class="btn-add-action-type"
      fab
      dark
      color="primary"
      to="/action-type/add/"
    >
      <v-icon dark>
        mdi-plus
      </v-icon>
    </v-btn>

    <!--Диалоговое окно для удаляения-->
    <v-dialog
      v-model="isShowDialogDelete"
      max-width="400">
      <v-card>
        <v-card-title class="text-h5 align-center">
          Ви дійсно хочете видалити?
        </v-card-title>
        <v-card-actions class="flex justify-center">
          <v-btn
            color="primary" 
            min-width="150"
            text
            @click="deleteActionType()"
          >
            Так
          </v-btn>
          <v-btn
            color="grey"
            min-width="150"
            text
            @click="closeDialogDelete()"
          >
            Нi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { HTTP } from '@/http-common';
import { mapGetters, mapMutations } from 'vuex';

  export default {
    name: 'PageActionType',
    data() {
      return {
        // Список тиаов акций
        actionsTypes: [],
        // Показать диалоговоеокно для удаления 
        isShowDialogDelete: false,
        // текущий id 
        actionTypeId: null,
      }
    },
    computed: {
      ...mapGetters(['isManager', 'isAdmin']),
    },
    mounted() {
      this.init();
    },
    methods: {
      ...mapMutations(['setSnackbar']),
      async init() {
        await this.getActionsTypes();
      },

      // Получить все типы акций
      async getActionsTypes() {
        try {
          const { data } = await HTTP.post('/get-list-action-type', { 
            'lang_key': 'ua' 
          });
          this.actionsTypes = (data?.content?.type) || [];
        } catch(error) {
          console.error(error);
        }
      },
      
      // Удалить тип акций
      async deleteActionType() {
        this.isShowDialogDelete = false;

        try {
          await HTTP.post('/delete-action-type', {
            'id': this.actionTypeId
          })
          this.setSnackbar({ type: 'success', text: 'Успiшно видалено' });
          await this.getActionsTypes();
        } catch(error) {
          this.setSnackbar({ type: 'error', text: error?.message || 'Помилка сервера' });
          console.error(error);
        }
      },

      // Показать диалоговое окно для удаления
      showDialogDelete(id) {
        this.isShowDialogDelete = true
        this.actionTypeId = id
      },

      // Закрыть диалоговое окно для удаления
      closeDialogDelete() {
        this.isShowDialogDelete = false 
        this.actionTypeId = null
      },
    },
  }

</script>

<style lang="sass" scoped>
.btn-add-action-type
  position: fixed
  bottom: 20px
  right: 20px
</style>
